import { createRouter, createWebHistory } from "vue-router";
//
//
// import Home from '../views/Home.vue'
import Landing from "../views/Landing.vue";
import LandingHome from "../views/LandingHome.vue";
import SimplifiedLanding from "../views/SimplifiedLanding.vue";
import Grid from "../views/Grid.vue";
import Main from "../views/Main.vue";

// const zeroMalariaRoute = '../views/apps/clones/zeromalaria/'

const routes = [
    {
        path: "/",
        // name: "Home",
        // component: Landing,
        component: LandingHome,
    },
    {
        path: "/a",
        // name: "Home",
        // component: Landing,
        component: SimplifiedLanding,
    },

    {
        path: "/main",
        name: "Main",
        component: Main,
    },
    {
        path: "/Landing",
        name: "Landing",
        component: Landing,
    },
    {
        path: "/grid",
        name: "Grid",
        component: Grid,
    },

    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
            return import(/* webpackChunkName: "about" */ "../views/About.vue");
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
