<script>
// import StarFlightCanvas from "../components/StarFlightCanvas.vue";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import $ from "jquery";

console.log(gsap, ScrollTrigger, $);
export default {
    // components: { StarFlightCanvas },
    name: "",

    mounted() {
        console.log(">> mounted()");
        // const flightPath = {
        //     curviness: 1.25,
        //     autoRotate: true,
        //     values: [{ x: 100, y: -20 }],
        // };
        gsap.registerPlugin(ScrollTrigger);
        gsap.timeline({
            scrollTrigger: {
                trigger: ".hero",
                toggleActions: "restart pause resume pause",
                start: "top top",
                scrub: 1,
                pin: true,
            },
        })
            
            .to(".hero-headline", {
                position: "relative",
                top: -window.innerHeight * 0.1,
                // width: "100%",
                width: window.innerWidth * .95,

                duration: 1,
                onComplete: () => {
                    // $(".hero-texts").removeClass("md:col-span-4").addClass("md:col-span-12");
                },
                onReverseComplete: () => {
                    // $(".hero-texts").addClass("md:col-span-4").removeClass("md:col-span-12");
                },
            })
            // .to(".hero-texts", {
            //     width: "100%",
            //     duration: .5,
            // })
            .to(
                ".hero-explainer",
                {
                    opacity: 0,
                    yPercent: -10,
                    duration: 0.5,
                },
                0
            )
            .to(
                ".hero-cta",
                {
                    position: "relative",
                    opacity: 0,
                    yPercent: window.innerHeight * 0.2,
                    duration: 0.7,
                },
                "-=.6"
            )
            .to(
                ".hero-manager",
                {
                    position: "absolute",
                    // left: window.innerWidth * .,
                    // x: - window.innerWidth * .5,
                    xPercent: 0,
                    duration: 1.5,
                },
                "-=1"
            )
        //     .to("#role-developer", {
        //         x: "-50%",
        //         duration: 2,
        //     })
        //     .to(".paper-plane", {
        //         x: 500,
        //         y: 200,
        //     });
        // gsap.timeline({
        //     scrollTrigger: {
        //         trigger: ".animation",
        //         toggleActions: "restart pause resume pause",
        //         start: "top top",
        //         scrub: 1,
        //         pin: true,
        //     },
        // })
        //     .to(".paper-plane", {
        //         x: 500,
        //         duration: 2,
        //     })
        //     .to(".paper-plane", {
        //         x: 500,
        //         y: 200,
        //     });
    },
};
</script>

<template>
    <div class="h-full">
        <!-- <star-flight-canvas class="hidden absolute h-full bg-green-400" /> -->

        <section class="hero h-full flex justify-center items-center">
            <div class="grid h-full grid-cols-12 px-5 md:px-0 mx-auto md:w-11/12 lg:w-10/12 xl:w-9/12 2xl:w-8/12">
                <div class="hero-texts z-10 text-left col-span-12 md:col-span-4 flex flex-col gap-3 justify-center items-start">
                    <h2 class="hero-headline bg-blue-200 text-4xl md:text-6xl font-black uppercase">
                        Logiciels et Applications
                        <span class="text-orangé"> sur mesure </span>
                    </h2>
                    <p class="hero-explainer text-lg">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi quod eos, nulla aliquid porro eum officiis esse mollitia saepe dicta ad veritatis nostrum
                    </p>
                    <a  class="hero-cta rounded font-bold uppercase bg-orangé px-3 py-2"> Créer mon projet </a>

                    <!-- <div></div> -->
                </div>
                <div class="col-span-2"></div>

                <div class="absolute bottom-0 left-0 w-auto md:relative col-span-6 flex justify-center items-end">
                    <div class="role hero-manager cursor-pointer h-4/5 bg-red-300">
                        <img class="h-full w-auto" src="/images/logo.png" alt="" />
                    </div>
                </div>
            </div>
        </section>

        <!-- <section class="process h-full bg-blue flex justify-center items-center">
            <div class="grid grid-rows-5 h-full bg-pink-600 mx-auto md:w-11/12 lg:w-10/12 xl:w-9/12 2xl:w-8/12">
                <h2 class="mt-10 row-span-1 bg-blue-400 process-headline text-5xl text-left font-black uppercase">
                    Logiciels et Applications <span class="text-orangé"> sur mesure </span>
                </h2>
                <div class="row-span-4 grid grid-cols-12 items-stretch bg-green-500">
                    <div class="bg-red-300 col-span-4 flex justify-center items-end">
                        <img class="h-full w-auto" src="/images/logo.png" alt="" />
                    </div>

                    <div class="text-left col-span-6 flex flex-col gap-3 justify-between items-center">
                        <div></div>
                        <div>
                            <img class="h-full w-auto" src="/art/priority_list.png" alt="" />
                        </div>
                        <a href="" class="process-cta rounded font-bold uppercase bg-orangé px-3 py-2"> Créer mon projet </a>
                        <div></div>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="animation h-full relative">
            <img class="paper-plane absolute left-0 top-1/2 w-24" src="/img/zeromalaria/artwork_small_en/1.jpg" alt="" />
        </section>

        <footer class="h-full bg-red-200 flex justify-center items-center">
            <h2>Footer</h2>
        </footer>
    </div>
</template>

<style>
#app {
    height: 100vh;
}
</style>
