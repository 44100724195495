<script>
// import StarFlightCanvas from "../components/StarFlightCanvas.vue";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default {
    // components: { StarFlightCanvas },
    name: "",

    mounted() {
        // const flightPath = {
        //     curviness: 1.25,
        //     autoRotate: true,
        //     values: [{ x: 100, y: -20 }],
        // };

        gsap.registerPlugin(ScrollTrigger);

        let textTl = gsap
            .timeline({
                scrollTrigger: {
                    trigger: ".hero",
                    toggleActions: "restart pause resume pause",
                    start: "top top",
                    scrub: 1,
                    pin: true,
                },
            })
            .to(".hero-headline", {
                y: "-90%",
                width: "100%",
                duration: 1.5,
            })
            .to(
                ".hero-explainer",
                {
                    opacity: 0,
                    y: "-80%",
                    duration: 0.5,
                },
                0
            )
            
            .to(
                ".hero-cta",
                {
                    y: "90%",
                    opacity: 0,
                    duration: 0.8,
                },
                4
            )
            .to(".hero-texts", {
                css: {
                    // className:"+='col-span-12'"
                    top: "20%",
                },

                duration: 1,
            })
            .to(".hero-amane-manager ", {
                x: -window.innerWidth * 0.8,
                duration: 3,
            }, 3)

        textTl
            .to("#role-developer", {
                x: "-50%",
                duration: 2,
            })
            .to(".paper-plane", {
                x: 500,
                y: 200,
            });
        // gsap.timeline({
        //     scrollTrigger: {
        //         trigger: ".animation",
        //         toggleActions: "restart pause resume pause",
        //         start: "top top",
        //         scrub: 1,
        //         pin: true,
        //     },
        // })
        //     .to(".paper-plane", {
        //         x: 500,
        //         duration: 2,
        //     })
        //     .to(".paper-plane", {
        //         x: 500,
        //         y: 200,
        //     });
    },
};
</script>

<template>
    <div class="h-full">
        <!-- <star-flight-canvas class="hidden absolute h-full bg-green-400" /> -->

        <div class="hero h-full bg-bleu text-white font-bold flex justify-center items-center">
            <div class="hero-grid relative gap-5 h-full grid-cols-12 mx-auto md:w-11/12 lg:w-10/12 xl:w-9/12 2xl:w-8/12">
                <div class="hero-texts absolute z-10 top-1/2 transform -translate-y-1/2 bg-green-200 text-left col-span-7 flex flex-col justify-center items-start gap-5">
                    <div class="hero-headline text-6xl w-full bg-red-100">Main Header Text Goes here</div>
                    <div class="hero-explainer bg-red-300">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste ea sit voluptatum autem eaque modi labore ut deleniti debitis dolore!
                    </div>
                    <a class="hero-cta inline bg-orangé">Call to Action</a>
                </div>
                <div class="bg-green-500">
                    <img class="hero-amane-manager absolute bottom-0 right-0 h-2/3 w-36" src="/img/zeromalaria/artwork_large_en/10.jpg" alt="" />
                </div>
            </div>
        </div>

        <section class="animation h-full relative">
            <img class="paper-plane absolute left-0 top-1/2 w-24" src="/img/zeromalaria/artwork_small_en/1.jpg" alt="" />
        </section>

        <footer class="h-full bg-red-200 flex justify-center items-center">
            <h2>Footer</h2>
        </footer>
    </div>
</template>

<style scoped>
#app {
    height: 100vh;
}

.hero-grid {
}

.hero-grid.mode-project {
}

.roles-container:hover .role {
    transform: scale(1.1);
}
/* .roles-container:hover #role-designer {
    transform: rotate(15deg) translate(20%, 0) scale(1.05);
}
.roles-container:hover #role-developer {
    transform: rotate(-5deg) translate(-20%, 0) scale(1.05);
} */

.role {
    transition: 0.3s ease-out;
    transform-origin: bottom;
}
.role .role-shadow {
    display: none;
}
#role-designer {
    transform: rotate(5deg) translate(10%, 0);
}
#role-developer {
    transform: rotate(0deg) translate(-10%, 0);
}
#role-designer:hover {
    transform: rotate(10deg) translate(20%, 0);
}
#role-manager:hover {
    /* transform: scale(1.15); */
}
#role-developer:hover {
    transform: rotate(10deg) translate(-20%, 0);
}
.role:hover {
    transform: scale(1.2);
}
.role:hover .role-shadow {
    display: block;
}
</style>
