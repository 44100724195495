<template>
    <div id="star-flight-canvas"></div>
</template>

<script>
const P5 = require("p5");
export default {
    name: "StarFlightCanvas",
    mounted() {
        const script = function (p5) {
            const stars = [];

            let speed = 5;
            let mouseDown = false;
            let clicks = 0;

            let fullscreenMode = true;
            let canvas;

            // NOTE: Set up is here
            p5.setup = (_) => {
                console.log(_);
                canvas = p5.createCanvas(p5.windowWidth, p5.windowHeight);
                canvas.parent("star-flight-canvas");

                // for (let i = 0; i < 1000; i++) {
                for (let i = 0; i < 500; i++) {
                    // stars.push(new Star(random(windowWidth / 2 - 100, windowWidth / 2 + 100), random(windowWidth / 2 - 100, windowWidth / 2 + 100)));
                    stars.push(new Star());
                }
            };
            p5.windowResized = () => {
                p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
            };

            // NOTE: Draw is here
            p5.draw = () => {
                // console.log(_, stars.length, speed);

                // p5.background(30, 30, 60);
                // p5.background(238, 238, 238);
                p5.background(255);

                p5.translate(p5.width / 2, p5.height / 2);
                for (var i = 0; i < stars.length; i++) {
                    stars[i].flight();
                    stars[i].draw();
                }
                if (mouseDown) {
                    speed += 1;
                    stars.push(new Star());
                }
                if (p5.random() < 0.3) {
                    // console.log(">> °");
                    stars.push(new Star());
                }

                if(stars.length > 5000){
                    stars.splice(Math.floor(stars.length/3))
                }
            };

            p5.mousePressed = () => {
                // while (mouseIsPressed) {
                // speed += 10;
                speed += p5.random(-10, 10);
                console.log(">>pressed", speed);
                // }
                mouseDown = true;
                console.log(">>pressed", speed);

                if (fullscreenMode) {
                    // var element = document.querySelector("#star-flight-canvas");
                    // element
                    //     .requestFullscreen()
                    //     .then(function () {
                    //         // element has entered fullscreen mode successfully
                    //         console.log(">> fullscreen");
                    //     })
                    //     .catch(function (error) {
                    //         // element could not enter fullscreen mode
                    //         // error message
                    //         console.log(error.message);
                    //     });
                }
            };

            p5.mouseReleased = () => {
                mouseDown = false;
                speed = 10;
            };

            p5.mouseClicked = (fxn) => {
                console.log("", fxn);
                clicks++;
                if (clicks % 5 == 0) {
                    speed *= -1;
                }
                if(p5.random() < .5){
                    speed *= -1;
                }
            };

            p5.mouseWheel = (fxn) => {
                speed += fxn.deltaY > 0 ? 1 : -0.5;
                // speed = map(fxn.deltaY, -3, 3, -10, 50)
            };

            function Star() {
                this.x = p5.random(-p5.width, p5.width);
                this.y = p5.random(-p5.height, p5.height);
                this.distance = p5.random(p5.width);

                let coolors = [
                    [34, 116, 165],
                    [247, 92, 3],
                    [241, 196, 15],
                    [217, 3, 104],
                    [0, 204, 102],
                    //
                    [249, 57, 67],
                    [126, 178, 221],
                    [68, 94, 147],
                    [22, 244, 208],
                    [255, 166, 43],
                ];

                var c;
                c = coolors[Math.floor(Math.random() * coolors.length)];
                c = [p5.random(30, 255), p5.random(30, 255), p5.random(30, 255)];
                this.color = p5.color(c);
                this.draw = function () {
                    var radius = 4;
                    // var opacity = p5.map(this.distance, p5.width, 0, 0, 1);
                    // this.color.setAlpha(opacity)
                    p5.fill(this.color);
                    p5.noStroke();
                    // radius = p5.map(this.distance, p5.width, 0, 0, 10);
                    radius = p5.map(this.distance, p5.width, 0, 1, 20);

                    var px = p5.map(this.x / this.distance, 0, 1, 0, p5.width);
                    var py = p5.map(this.y / this.distance, 0, 1, 0, p5.height);

                    // Steer towards mouse

                    // px += p5.mouseX / 10;
                    px += p5.mouseX / 5;
                    // py += p5.mouseY / 10;
                    py += p5.mouseY / 5;

                    // px += p5.mouseX / 5;
                    // py += p5.mouseY / 5;

                    p5.ellipse(px, py, radius);
                };

                this.flight = function () {
                    speed = p5.constrain(speed, -15, 15);
                    this.distance -= speed;
                    if (this.distance < speed) {
                        this.distance = p5.width;
                        this.x = p5.random(-p5.width, p5.width);
                        this.y = p5.random(-p5.height, p5.height);
                    }
                    if (this.distance > p5.width) {
                        this.distance = speed;
                        this.x = p5.random(-p5.width, p5.width);
                        this.y = p5.random(-p5.height, p5.height);
                    }
                };
            }
        };
        // NOTE: Use p5 as an instance mode

        new P5(script);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#star-flight-canvas {
    display: block;
    margin: 0 auto;
    padding: 0;
}
</style>
