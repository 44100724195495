<script>
import StarFlightCanvas from "../components/StarFlightCanvas.vue";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import $ from "jquery";
import Tooltip from "../components/Tooltip.vue";

console.log(gsap, ScrollTrigger, $);
export default {
    components: { StarFlightCanvas, Tooltip },
    name: "",

    data() {
        return {
            showContactForm: false,
            message: {
                name: null,
                email: null,
                content: null,
                type: {
                    web: false,
                    mobile: false,
                    other: false,
                },
            },
            errors: {
                message: {
                    name: "",
                    email: "",
                    content: "",
                    type: "",
                },
                contact: null,
            },
            messageValid: false,
            messageLoading: false,
            canSendMessage: true,
            messageSent: null,
        };
    },
    mounted() {
        console.log(">> mounted()");
        
    },
    computed: {
        isMessageValid() {
            return this.messageValid && this.message.name != null && this.message.email != null && this.message.content != null;
        },
    },
    methods: {
        openContactForm() {
            console.log(">> openContactForm")
            this.showContactForm = true;
            this.$router.push("/#contact");
            
        },
        closeContactForm() {
            console.log(">> closeContactForm")
            this.showContactForm = false;
            this.$router.push("/");
        },

        clearForm() {
            this.message = {
                name: null,
                email: null,
                content: null,
                type: {
                    web: false,
                    mobile: false,
                    other: false,
                },
            };
        },
        clearError() {},
        clearErrors() {
            this.errors = {
                message: {
                    name: "",
                    email: "",
                    content: "",
                    type: "",
                },
                contact: null,
            };
        },
        onFocus(field) {
            this.errors.message[field] = "";
        },
        onSubmit(field) {
            console.log(">> onSubmit", field);
            switch (field) {
                case "name":
                    if (this.message[field]) {
                        document.getElementById("input.message.email").focus();
                    }
                    break;
                case "email":
                    if (this.message[field]) {
                        document.getElementById("input.message.content").focus();
                    }
                    break;
                case "content":
                    if (this.message[field]) {
                        this.sendMessage();
                    }
                    break;

                default:
                    break;
            }
        },
        resetMessageForm() {
            console.log(">> resetMessageForm")
            this.closeContactForm();
            this.clearErrors();
            this.messageValid = false
            this.messageLoading = false
            this.canSendMessage = true
            this.messageSent = null
            this.clearForm();
        },
        async sendMessage() {
            console.log(">> sendMessage", this.message);
            this.messageLoading = true;

            this.clearErrors();
            this.messageValid = true;
            if (!this.message.name) {
                this.errors.message.name = "Oups, don't forget your name";
                this.messageValid = false;
            }
            if (!this.message.email) {
                this.errors.message.email = "I need your email to reach back to you";
                this.messageValid = false;
            }
            if (!this.message.content) {
                this.errors.message.content = "What's on your mind";
                this.messageValid = false;
            }

            if (!this.messageValid) {
                this.messageLoading = false;

                return;
            }

            /// ? Api call

            await this.sleep(3000);
            ///
            this.messageLoading = false;

            this.messageSent = true;
        },
        sleep: function (time) {
            return new Promise((resolve) => setTimeout(resolve, time));
        },
    },
};
</script>

<template>
    <div id="landing-home" class="h-full">
        <main id="main" :class="['relative z-10 h-full w-full select-none transition-all delay-200 duration-500 ease-in-out ', showContactForm ? 'transform -translate-x-96' : '']">
            <star-flight-canvas class="hidden absolute h-full bg-green-400" />
            <!-- <div class="absolute w-full h-full bg-black bg-opacity-10"></div> -->
            <div class="h-full flex flex-col items-stretch">
                <nav class="z-10 py-2 flex justify-between px-5 md:px-0 mx-auto w-full md:w-11/12 lg:w-10/12 xl:w-9/12 2xl:w-8/12">
                    <a href="/" class="cursor-pointer text-orangé text-3xl"> あ </a>
                    <div class="flex items-center">
                        <a @click="openContactForm" class="flex items-center gap-2 px-2 py-1 cursor-pointer bg-white hover:shadow font-medium"> Hi there, hello ! </a>
                    </div>
                </nav>
                <section class="h-full hero grid justify-center items-center">
                    <div class="hero grid h-full grid-cols-12 px-5 md:px-0 mx-auto md:w-11/12 lg:w-10/12 xl:w-9/12 2xl:w-8/12">
                        <div class="hero-texts z-10 text-left col-span-12 md:col-span-6 flex flex-col gap-3 justify-center items-start">
                            <h2 class="hero-headline text-4xl md:text-6xl font-black uppercase">
                                I BUILD <span class="text-orangé md:whitespace-nowrap"> PROBLEM-SOLVING </span> SOFWARE
                            </h2>
                            <p class="hero-explainer text-xl">I help turn your ideas into beautifully crafted web & mobile apps</p>
                            <button @click="openContactForm" class="whitespace-nowrap hero-cta cursor-pointer rounded font-bold uppercase bg-orangé px-3 py-2"> Let's get started now </button>

                            <!-- <div></div> -->
                        </div>
                        <!-- <div class="col-span-2"></div> -->

                        <div class="invisible md:visible absolute overflow-hidden bottom-0 left-0 w-auto md:relative col-span-6 flex justify-center items-end">
                            <img class="h-auto w-full mt-auto" src="/art/meh_.svg" alt="" />
                        </div>
                    </div>
                </section>
            </div>
        </main>

        <aside
            id="contact"
            :class="[
                'fixed flex flex-col justify-between transition-all delay-200 duration-500 ease-in-out top-0  bg-bleu p-5 w-full h-full md:w-96',
                showContactForm ? 'right-0' : '-right-96',
            ]"
        >
            <div class="text-white">
                <div class="p flex relative justify-center items-center">
                    <i @click="closeContactForm" class="material-icons absolute left-0 hover:bg-beige hover:bg-opacity-10 p-2 cursor-pointer">arrow_back</i>
                    <h2 class="capitalize text-2xl font-bold">Alright,</h2>
                </div>

                <h2 class="mt-16 capitalize text-2xl font-bold">Let's talk about your project</h2>
            </div>
            <div class="flex flex-col gap-3 font-medium">
                <transition name="slide-up">
                    <div v-show="!messageSent" class="relative w-full">
                        <!-- <Tooltip v-show="errors.message.name" :text="errors.message.name" position="up" /> -->
                        <Tooltip v-show="errors.message.name" :text="errors.message.name" position="down" />
                        <input
                            @keyup.enter="onSubmit('name')"
                            type="text"
                            @focus="onFocus('name')"
                            name="name"
                            v-model="message.name"
                            id="input.message.name"
                            required
                            placeholder="Name"
                            class="p-3 w-full font-medium outline-none"
                        />
                    </div>
                </transition>

                <transition name="slide-up">
                    <div v-show="!messageSent" class="relative w-full">
                        <!-- <Tooltip class="" v-show="errors.message.email" :text="errors.message.email" position="right" /> -->
                        <Tooltip class="md:hidden" v-show="errors.message.email" :text="errors.message.email" position="down" />
                        <Tooltip class="invisible md:visible whitespace-nowrap" v-show="errors.message.email" :text="errors.message.email" position="left" />
                        <input
                            @keyup.enter="onSubmit('email')"
                            type="email"
                            @focus="onFocus('email')"
                            name="email"
                            v-model="message.email"
                            id="input.message.email"
                            required
                            placeholder="Email"
                            class="p-3 w-full font-medium outline-none"
                        />
                    </div>
                </transition>

                <transition name="slide-up">
                    <div v-show="!messageSent" class="relative w-full">
                        <Tooltip class="md:hidden" v-show="errors.message.content" :text="errors.message.content" position="down" />
                        <Tooltip class="invisible md:visible" v-show="errors.message.content" :text="errors.message.content" position="up" />
                        <div class="bg-white relative w-full">
                            <div class="grid grid-cols-3 w-full p-2 text-bleu">
                                <a
                                    @click="message.type.web = !message.type.web"
                                    :class="['border p-1 cursor-pointer transition-all duration-300 ease-out hover:bg-orangé', message.type.web ? 'bg-orangé text-white' : '']"
                                    >Mobile</a
                                >
                                <a
                                    @click="message.type.mobile = !message.type.mobile"
                                    :class="[
                                        'border p-1 cursor-pointer transition-all duration-300 ease-out hover:bg-orangé',
                                        message.type.mobile ? 'bg-orangé text-white' : '',
                                    ]"
                                    >Web</a
                                >
                                <a
                                    @click="message.type.other = !message.type.other"
                                    :class="[
                                        'border p-1 cursor-pointer transition-all duration-300 ease-out hover:bg-orangé',
                                        message.type.other ? 'bg-orangé text-white' : '',
                                    ]"
                                    >Other</a
                                >
                            </div>
                            <textarea
                                @keyup.enter="onSubmit('content')"
                                @focus="onFocus('content')"
                                type="text"
                                name="content"
                                id="input.message.content"
                                v-model="message.content"
                                required
                                placeholder="Tell me about the project"
                                rows="5"
                                class="p-3 py-1 w-full font-medium outline-none"
                            ></textarea>
                        </div>
                    </div>
                </transition>

                <transition name="slide-fade-up-delay">
                    <div v-show="messageSent" class="mb-32 transition-all duration-300 ease-out text-white font-bold text-4xl transform hover:skew-x-2 hover:bg-beige hover:bg-opacity-20 p-2">Thanks for reaching out, I'll get back to you soon 😃</div>
                </transition>
            </div>

            <div class="relative">
                <transition name="slide-up">
                    <button
                        v-show="!messageLoading || !messageSent"
                        @click="sendMessage"
                        :class="['absolute left-0 bottom-0 w-full text-center font-bold uppercase text-bleu', isMessageValid ? 'bg-orangé md:bg-blue-100' : 'bg-blue-50']"
                    >
                        Send
                    </button>
                </transition>
                <transition name="slide-up">
                    <button v-show="messageLoading" @click="sendMessage" class="absolute left-0 bottom-0 w-full text-center bg-orangé font-bold uppercase text-bleu">
                        Sending
                    </button>
                </transition>
                <transition name="slide-up">
                    <button v-show="messageSent" @click="resetMessageForm" class="absolute left-0 bottom-0 w-full text-center bg-orangé font-bold uppercase text-bleu">
                        close
                    </button>
                </transition>
            </div>
        </aside>
    </div>
</template>

<style>
#app {
    height: 100vh;
}
#landing-home{
    overflow: hidden !important;
}
</style>
