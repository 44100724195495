<template>
    <transition :name="'slide-' + position">
        <span :class="['absolute z-30 bg-' + color + '-500 px-1 py-0.5 text-white rounded lil-triangle '+' '+color+' ' + position]">{{ text }}</span>
    </transition>
</template>

<script>
export default {
    name: "Tooltip",
    props: {
        color: {
            default: "red",
        },
        text: {
            default: "Tip",
        },
        position: {
            default: "down",
        },
    },
};
</script>

<style>


:root {
    --red: #ef4444;
    --color: #ef4444;
    --green: #44ef44;
    --blue: #4444ef;
    --gray: #4444;
    --white: #fff;
}

/*  */

.lil-triangle.up {
    top: 100%;
    left: 20%;
    transform: translateX(-20%);
}
.lil-triangle.up-right {
    top: 100%;
    right: 20%;
    transform: translateX(20%);
}

.lil-triangle.right {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
}
.lil-triangle::after {
    content: "";
    position: absolute;
    border-width: 5px;
    border-style: solid;
}
.lil-triangle.left {
    right: 97%;
    top: 50%;
    transform: translateY(-50%);
}
.lil-triangle.down {
    bottom: 100%;
    left: 20%;
    transform: translateX(-20%);
}

.lil-triangle.up::after {
    bottom: 97%;
    left: 50%;
    transform: translateX(-50%);
    border-color: transparent transparent var(--color) transparent;
}


.lil-triangle.right::after {
    right: 97%;
    top: 50%;
    transform: translateY(-50%);
    border-color: transparent var(--color) transparent transparent;
}

.lil-triangle.down::after {
    content: "";
    position: absolute;
    border-width: 5px;
    border-style: solid;
    top: 97%;
    left: 50%;
    transform: translateX(-50%);
    border-color: var(--color) transparent transparent transparent;
}
.lil-triangle.left::after {
    left: 97%;
    top: 50%;
    transform: translateY(-50%);
    border-color: transparent transparent transparent var(--color);
}


/*  */
/* Red */
/* .lil-triangle.up.red::after {
    border-color: transparent transparent var(--red) transparent !important;
}
.lil-triangle.right.red::after {
    border-color: transparent var(--red) transparent transparent !important;
}
.lil-triangle.down.red::after {
    border-color: var(--red) transparent transparent transparent !important;
}
.lil-triangle.left.red::after {
    border-color: transparent transparent transparent var(--red) !important;
} */
/* Green */
/* .lil-triangle.up.red::after {
    border-color: transparent transparent var(--red) transparent !important;
}
.lil-triangle.right.red::after {
    border-color: transparent var(--red) transparent transparent !important;
}
.lil-triangle.down.red::after {
    border-color: var(--red) transparent transparent transparent !important;
}
.lil-triangle.left.red::after {
    border-color: transparent transparent transparent var(--red) !important;
} */

</style>
